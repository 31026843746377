import React from 'react';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import { Link } from 'gatsby';
import { Button, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { Banner } from 'src/components/shared';

import * as checkoutStyles from 'src/styles/checkout.module.scss';

const PurchaseError = ({ shopifyUrl, message, isCheckout = true }) => (
    <div className={checkoutStyles.checkoutPageWrapper}>
        <Grid className="warning" centered>
            <Grid.Row>
                <Grid.Column>
                    <Banner type="WARNING" withIcon>
                        {message}
                    </Banner>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column textAlign="justified">
                    {isCheckout ? (
                        <p>
                            Please return to your cart, make any necessary modifications, and try
                            your purchase again.
                        </p>
                    ) : (
                        <p>
                            Your request could not be completed due to the above issue. Please try
                            again.
                        </p>
                    )}
                    <p>
                        For immediate assistance, please{' '}
                        <a href={`${shopifyUrl}/pages/contact`}>contact us</a>.
                    </p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column textAlign="center">
                    {isCheckout ? (
                        <Button as="a" href={`${shopifyUrl}/cart`} fluid>
                            Take me to my Cart
                        </Button>
                    ) : (
                        <Button as={Link} to="/activate" fluid>
                            Try again
                        </Button>
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column textAlign="center">
                    <a href={shopifyUrl} className="bold upper pv-icon pv-arrow-left">
                        Back to home
                    </a>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
);

const mapStateToProps = (state) => ({
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
});

export default connect(mapStateToProps)(PurchaseError);

import React, { useEffect, useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';

import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import withLocation from 'src/components/withLocation';
import { LinkWithArrow, ConfirmationPage } from 'src/components/shared';
import ErrorPage from 'src/components/shared/error-page/error-page.component';
import PurchaseError from 'src/components/checkout/PurchaseError';

import { redirect } from 'src/utils/url-utils';
import { format } from 'date-fns';
import { ZELDA_HOME_LINK } from 'src/constants/url-constants';

const connector = connect(
    (state: IState) => ({
        code: Selectors.subscriptions.setActivationCode.code(state),
        activationProduct: Selectors.subscriptions.setActivationCode.product(state),
        shopifyUrl: Selectors.config.shopifyUrl(state),
        loggedIn: Selectors.auth.loggedIn(state),
        response: Selectors.subscriptions.activateSubscription.response(state),
        loading: Selectors.subscriptions.activateSubscription.loading(state),
        errorMessage: Selectors.subscriptions.activateSubscription.errorMessage(state),
        subscription: Selectors.subscriptions.activateSubscription.subscription(state),
    }),
    {
        loginRequired: (activationCode: string) =>
            Actions.web.loginRequired({
                state: { activationCode },
            }),
        clearActivationCode: () => Actions.subscriptions.setActivationCode.clear(),
        activateSubscription: (code: string) =>
            Actions.subscriptions.activateSubscription.trigger({ code }),
        triggerEntitlementsWatcher: () => Actions.auth.watchForEntitlements.trigger(),
        getTokenAttributes: () => Actions.account.getTokenAttributes.trigger(),
    }
);

const ActivationSuccess = ({
    loggedIn,
    shopifyUrl,
    activationProduct,
    code,
    loginRequired,
    activateSubscription,
    clearActivationCode,
    loading,
    errorMessage,
    subscription,
    response,
    triggerEntitlementsWatcher,
    getTokenAttributes,
}: ConnectedProps<typeof connector>) => {
    const onConfirm = useCallback(() => {
        getTokenAttributes();
        redirect(ZELDA_HOME_LINK);
    }, [getTokenAttributes]);

    useEffect(() => {
        if (!loggedIn) {
            loginRequired(code);
        } else if (code) {
            activateSubscription(code);
            triggerEntitlementsWatcher();
        }
    }, [activateSubscription, clearActivationCode, code, loggedIn, loginRequired]);

    const isReady = response && activationProduct;
    if (loading === false && !isReady) {
        // called activateSub and got a non-200 HTTP response
        return <ErrorPage code="500" />;
    }

    if (!loggedIn || loading || !response) {
        return null;
    }

    if (response.response_code > 0) {
        // application error from API
        const msg =
            response.response_code === 1101
                ? 'Looks like you already have streaming access'
                : errorMessage;
        return <PurchaseError shopifyUrl={shopifyUrl} message={msg} isCheckout={false} />;
    }

    const {
        subscription_details: { interval, interval_type },
    } = activationProduct;
    const durationDescription = interval === 1 ? interval_type : `${interval_type}s`;

    const { expiration_date } = subscription;
    const formattedEndDate =
        (expiration_date && format(new Date(expiration_date), 'MM/dd/yy')) || '';

    return (
        <ConfirmationPage
            title="Membership Activated"
            subtitle={`Your ${interval}-${interval_type} access begins today ${
                formattedEndDate && `and ends ${formattedEndDate}`
            }`}
        >
            <p className="p1 margin-top--small margin-bottom--large">
                If you want to continue your membership past {interval} {durationDescription}, you
                may do so in your account settings.
            </p>

            <p className="p1 margin-vertical--small">
                Why wait? Get after those goals starting now.
            </p>

            <Button onClick={onConfirm} secondary>
                Start Streaming
            </Button>

            <LinkWithArrow
                className="margin-top--xlarge"
                centered
                href={`${shopifyUrl}/pages/contact`}
                label="Questions? Contact Us"
            />
        </ConfirmationPage>
    );
};

export default connector(withLocation(ActivationSuccess));
